/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/scss/bootstrap.scss";
// regular style toast
@import '~ngx-toastr/toastr';

// ng-select default theme
@import "~@ng-select/ng-select/themes/default.theme.css";


@import '~animate.css/animate.min';


html, body { height: 100%; }
body { margin: 0;
-webkit-font-smoothing: antialiased;
}

* {
  font-family: 'Montserrat'!important;
}


@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-100.ttf") format("ttf");
  font-display:auto;font-style:normal;font-weight:100;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-200.ttf") format("ttf");
  font-display:auto;font-style:normal;font-weight:200;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-300.ttf") format("ttf");
  font-display:auto;font-style:normal;font-weight:300;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-400.ttf");
    font-display:auto;font-style:normal;font-weight:400;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-500.ttf");
  font-display:auto;font-style:normal;font-weight:500;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-600.ttf");
  font-display:auto;font-style:normal;font-weight:600;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-700.ttf");
  font-display:auto;font-style:normal;font-weight:700;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-800.ttf");
  font-display:auto;font-style:normal;font-weight:800;
}
@font-face {
  font-family:'Montserrat';
  src:url("./assets/Montserrat/Montserrat-900.ttf");
  font-display:auto;font-style:normal;font-weight:900;
}


@font-face {
  font-family:'Baloo Tammudu 2';
  src:url("./assets/fonts/Baloo_Tammudu_2/BalooTammudu2-400.ttf");
  font-display:auto;font-style:normal;font-weight:400;
}
@font-face {
  font-family:'Baloo Tammudu 2';
  src:url("./assets/fonts/Baloo_Tammudu_2/BalooTammudu2-500.ttf");
  font-display:auto;font-style:normal;font-weight:500;
}
@font-face {
  font-family:'Baloo Tammudu 2';
  src:url("./assets/fonts/Baloo_Tammudu_2/BalooTammudu2-600.ttf");
  font-display:auto;font-style:normal;font-weight:600;
}
@font-face {
  font-family:'Baloo Tammudu 2';
  src:url("./assets/fonts/Baloo_Tammudu_2/BalooTammudu2-700.ttf");
  font-display:auto;font-style:normal;font-weight:700;
}
@font-face {
  font-family:'Baloo Tammudu 2';
  src:url("./assets/fonts/Baloo_Tammudu_2/BalooTammudu2-800.ttf");
  font-display:auto;font-style:normal;font-weight:800;
}


@font-face {
  font-family:'Chathura';
  src:url("./assets/fonts/Chathura/Chathura-100.ttf");
  font-display:auto;font-style:normal;font-weight:100;
}
@font-face {
  font-family:'Chathura';
  src:url("./assets/fonts/Chathura/Chathura-300.ttf");
  font-display:auto;font-style:normal;font-weight:300;
}
@font-face {
  font-family:'Chathura';
  src:url("./assets/fonts/Chathura/Chathura-400.ttf");
  font-display:auto;font-style:normal;font-weight:400;
}
@font-face {
  font-family:'Chathura';
  src:url("./assets/fonts/Chathura/Chathura-700.ttf");
  font-display:auto;font-style:normal;font-weight:700;
}
@font-face {
  font-family:'Chathura';
  src:url("./assets/fonts/Chathura/Chathura-800.ttf");
  font-display:auto;font-style:normal;font-weight:800;
}

:root {

  /* Background-colors */
  --app-content-background: #f6f9fa;
  --app-content-background-white: #fff;

  /* Margins and Paddings */
  --app-margin-12: 0.75rem;
  --app-margin-14: 0.875rem;
  --app-margin-16: 1rem;
  --app-margin-20: 1.25rem;

   /* Font Sizes */
  --app-font-size-12: 0.75rem;
  --app-font-size-14: 0.875rem;
  --app-font-size-16: 1rem;
  --app-font-size-18: 1.125rem;
  --app-font-size-20: 1.25rem;

  /* Font Colors */
  --app-font-color: lightseagreen;
  --app-font-color-white: #fff;

   /* Font weights */
  --app-font-weight-100: 100;
  --app-font-weight-200: 200;
  --app-font-weight-300: 300;
  --app-font-weight-400: 400;
  --app-font-weight-500: 500;
  --app-font-weight-600: 600;
  --app-font-weight-700: 700;
  --app-font-weight-800: 800;
  --app-font-weight-900: 900;

   /* Border-radius */
  --app-border-radius-4: 0.25rem;
  --app-border-radius-10: 0.625rem;
  --app-border-radius-round: 50%;

  /* letter-spacing */
  --app-letter-spacing: 1px;

  /* Box Shadow */
  --app-box-shadow: 0 7px 14px -6px #ccc;

  /* Filter-Drop shadow */
  --app-filter-dropshadow: drop-shadow(2px 4px 6px #ccc);
}

.div-qrcode {
  .qrcode {
    svg {
        height: 150px;
        width: 150px;
    }
}
}

// This is login country input box

.iti {
  width: 100%!important;
}

.breadcrumbs {
  margin-bottom: calc(var(--app-margin-20) * 1.5);
}

a:hover {
  color: #FFBE61;
  text-decoration: underline;
}

.breadcrumb {
  display: inline-block;
  position: relative;
  margin-top: calc(var(--app-margin-20) / 5);
  margin-right: calc(var(--app-margin-16) / 2);
  margin-bottom: calc(var(--app-margin-20) / 5);
  padding: 10px;
  background-color: #222F3F;
  color: var(--app-font-color-white);
  white-space: nowrap;
  font-size: var(--app-font-size-16);
  letter-spacing: var(--app-letter-spacing);
  border-radius: 0 var(--app-border-radius-4) var(--app-border-radius-4) 0;
  font-weight: var(--app-font-weight-600);
}
.breadcrumb:after {
  content: "";
  position: absolute;
  top: 0;
  left: 99%;
  border-width: 1.5em 0.75em 1.25em 0.75em;
  border-style: solid;
  border-color: transparent transparent transparent #222F3F;
}
.breadcrumb + .breadcrumb {
  margin-left: var(--app-margin-12);
}
.breadcrumb + .breadcrumb:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 99%;
  border-width: 1.5em 0em 1.25em 0.75em;
  border-style: solid;
  border-color: #222F3F #222F3F #222F3F transparent;
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px)  {

  .breadcrumbs {
    margin-bottom: 16px;
}

  .breadcrumb {
    padding: calc(var(--app-margin-12) / 2) calc(var(--app-margin-20) / 2) !important;
    font-size: var(--app-font-size-12);
  }
  .breadcrumb:after {
      top: 0px;
      border-width: 1.2em 0.65em 1.2em 0.65em;
  }
  .breadcrumb + .breadcrumb {
      margin-left: 0.75em;
  }
  .breadcrumb + .breadcrumb:before {
    border-width: 1.25em 0.25em 1.25em 0.65em;
  }
}

.outer-card {

  .mat-card-header {

    .mat-card-header-text {
      margin: 0;

      .mat-card-title {
        margin-bottom: 24px;
        font-size: 24px;
        color: #0fc5b0;
      }

      .mat-card-subtitle {
        color: white;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

// Mat card Media queries

@media screen and (min-device-width: 481px) and (max-device-width: 767px)  {

  .outer-card .mat-card-header .mat-card-header-text .mat-card-title {
      margin-bottom: 16px;
      font-size: 20px!important;
  }

  .outer-card .mat-card-header .mat-card-header-text .mat-card-subtitle {
    font-size: 12px;
    margin-bottom: 0;
  }

  .mat-card {
    padding: 10px!important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px)  {

  .outer-card .mat-card-header .mat-card-header-text .mat-card-title {
      margin-bottom: 16px;
      font-size: 18px!important;
  }

  .outer-card .mat-card-header .mat-card-header-text .mat-card-subtitle {
    font-size: 12px;
    margin-bottom: 0;
  }

  .mat-card {
    padding: 10px!important;
  }
}

@media (min-width: 1601px) {

  .ais-Pagination-link {
    font-size: 15px;
  }
  .ng-select.ng-select-single .ng-select-container {
    font-size: var(--app-font-size-16);
    height: 40px;
    font-weight: 600;
  }

  .uploadcare--widget__button  {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 10px 24px;
  }

  .ql-editor p {
    font-size: 20px;
    font-weight: 600;
  }

  .ais-Highlight {
    font-size: 15px !important;
    text-transform: capitalize !important;
  }

  .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count {
    font-size: 15px !important;

  }

.facet-category-title {
    font-size: 18px !important;
  }

 .ais-Panel-header {
    font-size: 16px !important;
  }

  .ais-SearchBox-input {
    height: 45px !important;
    padding: 15px 15px !important;
    font-size: 20px !important;
  }

  .results-wrapper {
   padding: 10px 0px 0px 340px !important;
 }
}



@media (min-width: 1281px) and (max-width: 1600px) {

  .ais-Pagination-link {
    font-size: 14px !important;
  }

  .ais-Highlight {
    font-size: 14px !important;
    text-transform: capitalize !important;
  }

  .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count {
    font-size: 14px !important;

  }

.facet-category-title {
    font-size: 17px !important;
  }

 .ais-Panel-header {
    font-size: 15px !important;
  }

  .ais-SearchBox-input {
    height: 100% !important;
    padding: 10px 15px !important;
    font-size: 18px !important;
    height: 40px  !important;
  }
  .uploadcare--widget__button  {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 10px 24px;
  }

  .ql-editor p {
    font-size: 18px;
    font-weight: 600;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  aside {
    top: 130px !important;
  }
  .ais-Highlight {
    font-size: 13px !important;
    text-transform: capitalize !important;
  }

  .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count {
    font-size: 13px !important;

  }

.facet-category-title {
    font-size: 16px !important;
  }

 .ais-Panel-header {
    font-size: 14px !important;
  }

  .ais-SearchBox-input {
    height: 38px !important;
    padding: 10px 15px !important;
    font-size: 16px !important;
  }


  .uploadcare--widget__button  {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 10px 24px;
  }

  .ql-editor p {
    font-size: 16px;
    font-weight: 600;
  }
}

@media (min-width: 801px) and  (max-width: 1024px) {
  svg {
    height: 150px;
    width: 150px;
  }

  .ais-Pagination-link {
    font-size: 12px !important;
  }

  .uploadcare--widget__button  {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 8px 16px;
  }

  .ql-editor p {
    font-size: 16px;
    font-weight: 600;
  }

  .ais-Highlight {
    font-size: 12px !important;
    text-transform: capitalize !important;
  }

  .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count {
    font-size: 12px !important;

  }

.facet-category-title {
    font-size: 15px !important;
  }

 .ais-Panel-header {
    font-size: 13px !important;
  }
  aside {
    width: 240px  !important;
    top: 125px !important;
  }
  .ais-SearchBox-input {
    height: 36px !important;

  }
}

@media (min-width: 620px) and  (max-width: 800px) {

  .qrcode {

    svg {
      height: 130px;
      width: 130px;
    }
  }


  .ng-select .ng-select-container {
    font-size: 11px !important;
    min-height: 32px !important;
    height: 32px !important;
  }


  .ais-Pagination-link {
    font-size: 11px !important;
    padding: 6px 12px !important;
  }

  .ais-Highlight {
    font-size: 11px !important;
    text-transform: capitalize !important;
  }

  .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-count {
    font-size: 11px !important;

  }

.facet-category-title {
    font-size: 14px !important;
  }

 .ais-Panel-header {
    font-size: 12px !important;
  }
  .ais-SearchBox-input {
    height: 34px !important;

  }
}

@media (max-width: 768px) {

  aside {
    display: none;
  }
  svg {
    height: 130px;
    width: 130px;
  }

  .ng-select .ng-select-container {
    font-size: 12px !important;
    min-height: 30px !important;
    height: 30px !important;
  }

  .ais-Pagination-link {
    padding: 6px 12px !important;
    font-size: 12px !important;
  }

  aside {
    width: 260px  !important;
    top: 125px !important;
  }

  .ais-Pagination {
    margin-top: 16px !important;
    padding: 0 !important;
}


}

.ais-Pagination-link {
  padding: 8px 14px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
}

.ais-Pagination {
  margin: 0 ;
  padding: 0 ;
}

.ais-Stats {
  margin-bottom: 0 !important;
}

// Algolia

$gray-light: #EEE;
$gray: #888;
$dark-blue: #222F3F;
$brand-primary: #0063C3;
$primary-color: #FFBE61;
$secondary-color: #B32500;
$highlight-color: #FFFFE4;
$shadow-color: rgba(0, 0, 0, .1);

$header-height: 60px;
$sidebar-width: 230px;

@mixin clearfix {
  clear: both;

  // &:after {
  //   content: "";
  //   display: table;
  //   clear: both;
  // }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  background-color: #eee;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FFF;
  background-image: -webkit-gradient(linear,40% 0%,75% 84%,from(#ccc),to(#aaa),color-stop(.6,#aaa))
}

aside.scrollbar-products {
  top: 90px;
  position: fixed;
  overflow-y: auto;
  left: 0;
  bottom: 0;
  // width: $sidebar-width;
  width: 300px;
  padding: 20px 16px 16px;
  // border-right: 1px solid $gray-light;
  background: #FFFFFF;

  .facet-category-title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0 0 10px;
  }

  .facet-wrapper {
    padding-bottom: 12px;
    border-bottom: solid 1px $gray-light;
    margin-bottom: 12px;
    font-size: .83em;
  }

  .ais-HierarchicalMenu,
  .ais-RefinementList,
  .ais-PriceRanges,
  .ais-RatingMenu {
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: right
  }

  .ais-Panel-header {
    color: #777;
    font-weight: bold;
    margin: 0 0 5px;
    font-size: 14px;
  }

  .ais-RefinementList-label {
    color: #000000;
    cursor: pointer;
    font-weight: normal;

    &:hover {
      color: $secondary-color;
      text-decoration: none;
    }

    &.active {
      font-weight: bold;
    }

    input {
      margin-right: 4px;
    }
  }

  .ais-Highlight {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .facet-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  .ais-RefinementList-count {
    margin-left: 4px;
    color: $gray;

    &:before {
      content: "(";
    }

    &:after {
      content: ")";
    }
  }

  #colors {
    @include clearfix;
  }

  .facet-color {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    margin: 0 6px 6px;
    display: block;
    overflow: hidden;
    float: left;
    &.checked {
      box-shadow: inset 0 0 0 4px rgba(#FFFFFF, .5);
    }
    &[data-facet-value="White"] {
      background-color: #FFFFFF;
      box-shadow: inset 0 0 0 1px #CCC;
      &.checked {
        box-shadow: inset 0 0 0 4px rgba(#000000, .1);
      }
    }
    &[data-facet-value="Black"] {
      background-color: #000000;
    }
    &[data-facet-value="Black-brown"] {
      background-color: #382919;
    }
    &[data-facet-value="Pink"] {
      background-color: #C0116D;
    }
    &[data-facet-value="Gray"] {
      background-color: #6F6E6C;
    }
    &[data-facet-value="Blue"] {
      background-color: #5182A1;
    }
    &[data-facet-value="Green"] {
      background-color: #1E9C5E;
    }
    &[data-facet-value="Brown"] {
      background-color: #7B6A63;
    }
    &[data-facet-value="Red"] {
      background-color: #BC1C1A;
    }
    &[data-facet-value="Dark gray"] {
      background-color: #444348;
    }
    &[data-facet-value="Silver color"] {
      background-color: #AAAAA8;
    }
    &[data-facet-value="Light brown"] {
      background-color: #C19A6E;
    }
    &[data-facet-value="Black-brown stain"] {
      background-color: #4B4640;
    }
    &[data-facet-value="Off-white"] {
      background-color: #D0C8B4;
    }
    &[data-facet-value="Beige"] {
      background-color: #C9B8A3;
    }
    &[data-facet-value="Light green"] {
      background-color: #ABBD9B;
    }
    &[data-facet-value="White stain"] {
      background-color: #E4E1DC;
    }
    &[data-facet-value="Turquoise"] {
      background-color: #46BCC9;
    }
    &[data-facet-value="Birch effect"] {
      background-color: #CBBFA4;
    }
    &[data-facet-value="Yellow"] {
      background-color: #F5E500;
    }
    &[data-facet-value="Dark blue"] {
      background-color: #374063;
    }
    &[data-facet-value="Galvanized"] {
      background-color: #726F6A;
    }
    &[data-facet-value="Birch"] {
      background-color: #726F6A;
    }
    &[data-facet-value="Dark brown"] {
      background-color: #74523E;
    }
    &[data-facet-value="High gloss gray"] {
      background-color: #E5E5E5;
    }
    &[data-facet-value="Gray/white"] {
      background-color: #E5E5E5;
    }
    &[data-facet-value="Red/white"] {
      background-color: #FA757B;
    }
    &[data-facet-value="Clear"] {
      background-color: #D5DAE0;
    }
    &[data-facet-value="Matte black"] {
      background-color: #616872;
    }
    &[data-facet-value="Matte white"] {
      background-color: #DCDBD7;
    }
    &[data-facet-value="White stained oak effect"] {
      background-color: #E7E8E0;
    }
    // to fix - add more colors
  }
}


aside {
  top: 145px;
  position: fixed;
  overflow-y: auto;
  left: 0;
  bottom: 0;
  // width: $sidebar-width;
  width: 300px;
  padding: 20px 16px 16px;
  // border-right: 1px solid $gray-light;
  background: #FFFFFF;

  @media (min-width:1601px) {
    top: 150px;
    width: 330px;
  }

  .facet-category-title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0 0 10px;
  }

  .facet-wrapper {
    padding-bottom: 12px;
    border-bottom: solid 1px $gray-light;
    margin-bottom: 12px;
    font-size: .83em;
  }

  .ais-HierarchicalMenu,
  .ais-RefinementList,
  .ais-PriceRanges,
  .ais-RatingMenu {
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: right
  }

  .ais-Panel-header {
    color: #777;
    font-weight: bold;
    margin: 0 0 5px;
    font-size: 14px;
  }

  .ais-RefinementList-label {
    color: #000000;
    cursor: pointer;
    font-weight: normal;

    &:hover {
      color: $secondary-color;
      text-decoration: none;
    }

    &.active {
      font-weight: bold;
    }

    input {
      margin-right: 4px;
    }
  }

  .ais-Highlight {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .facet-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  .ais-RefinementList-count {
    margin-left: 4px;
    color: $gray;

    &:before {
      content: "(";
    }

    &:after {
      content: ")";
    }
  }

  #colors {
    @include clearfix;
  }

  .facet-color {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    margin: 0 6px 6px;
    display: block;
    overflow: hidden;
    float: left;
    &.checked {
      box-shadow: inset 0 0 0 4px rgba(#FFFFFF, .5);
    }
    &[data-facet-value="White"] {
      background-color: #FFFFFF;
      box-shadow: inset 0 0 0 1px #CCC;
      &.checked {
        box-shadow: inset 0 0 0 4px rgba(#000000, .1);
      }
    }
    &[data-facet-value="Black"] {
      background-color: #000000;
    }
    &[data-facet-value="Black-brown"] {
      background-color: #382919;
    }
    &[data-facet-value="Pink"] {
      background-color: #C0116D;
    }
    &[data-facet-value="Gray"] {
      background-color: #6F6E6C;
    }
    &[data-facet-value="Blue"] {
      background-color: #5182A1;
    }
    &[data-facet-value="Green"] {
      background-color: #1E9C5E;
    }
    &[data-facet-value="Brown"] {
      background-color: #7B6A63;
    }
    &[data-facet-value="Red"] {
      background-color: #BC1C1A;
    }
    &[data-facet-value="Dark gray"] {
      background-color: #444348;
    }
    &[data-facet-value="Silver color"] {
      background-color: #AAAAA8;
    }
    &[data-facet-value="Light brown"] {
      background-color: #C19A6E;
    }
    &[data-facet-value="Black-brown stain"] {
      background-color: #4B4640;
    }
    &[data-facet-value="Off-white"] {
      background-color: #D0C8B4;
    }
    &[data-facet-value="Beige"] {
      background-color: #C9B8A3;
    }
    &[data-facet-value="Light green"] {
      background-color: #ABBD9B;
    }
    &[data-facet-value="White stain"] {
      background-color: #E4E1DC;
    }
    &[data-facet-value="Turquoise"] {
      background-color: #46BCC9;
    }
    &[data-facet-value="Birch effect"] {
      background-color: #CBBFA4;
    }
    &[data-facet-value="Yellow"] {
      background-color: #F5E500;
    }
    &[data-facet-value="Dark blue"] {
      background-color: #374063;
    }
    &[data-facet-value="Galvanized"] {
      background-color: #726F6A;
    }
    &[data-facet-value="Birch"] {
      background-color: #726F6A;
    }
    &[data-facet-value="Dark brown"] {
      background-color: #74523E;
    }
    &[data-facet-value="High gloss gray"] {
      background-color: #E5E5E5;
    }
    &[data-facet-value="Gray/white"] {
      background-color: #E5E5E5;
    }
    &[data-facet-value="Red/white"] {
      background-color: #FA757B;
    }
    &[data-facet-value="Clear"] {
      background-color: #D5DAE0;
    }
    &[data-facet-value="Matte black"] {
      background-color: #616872;
    }
    &[data-facet-value="Matte white"] {
      background-color: #DCDBD7;
    }
    &[data-facet-value="White stained oak effect"] {
      background-color: #E7E8E0;
    }
    // to fix - add more colors
  }
}

.results-wrapper {
  // padding: 70px 10px 10px ($sidebar-width + 10px);
  padding: 10px 0px 0px 316px;
  background: white;
}

.ais-RangeInput {

  .ais-RangeInput-form {

    .ais-RangeInput-submit {
      border: 1px solid #0096db;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 2px;
      padding: 5px;
      margin-top: 16px;
      margin-left: 10px;
      height: 30px;
      border-radius: 4px;
      color: #0096db;

      &:hover {
        color: white;
      }
    }

    .ais-RangeInput-separator {
      margin: 0 5px;
      margin-top: 18px;
  }

    .ais-RangeInput-label {
      width: 50%;

     .ais-RangeInput-currency {
        font-size: 14px;
        content: "\20B9" !important;
      }

      .ais-RangeInput-input--min {
        width: 100px;
        padding: 5px;
        font-size: 13px;
        border-radius: 4px;
        border: none;
        box-shadow: 2px 2px 4px 0px #aaa;
      }

      .ais-RangeInput-input--max {
        width: 100px;
        padding: 5px;
        font-size: 13px;
        border-radius: 4px;
        border: none;
        box-shadow: 2px 2px 4px 0px #aaa;
      }
    }
  }
}

.ais-ClearRefinements-button::before {
  content: "✄" !important;
  color: #000!important;
}

.ais-RefinementList {

  .ais-RefinementList-list {
    margin-top: 10px;

    .ais-RefinementList-item {

      .ais-RefinementList-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        .ais-RefinementList-count {
          padding: 2px;
          font-size: .8rem;
          color: #000;
          background-color: #FFBE61;
          border-radius: 4px;
          filter: drop-shadow(1px 1px 2px #aaa);
          font-weight: 600;
          letter-spacing: 1px;
          font-size: 12px;
        }

        .ais-RefinementList-labelText {
          flex-grow: 1;
          text-align: left;
        }
      }
    }
  }
}

.ais-RefinementList-showMore {
  padding: 5px 10px;
    background: #222F3F;
    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;

    &:hover {
      filter: drop-shadow(2px 4px 6px #aaa);
      background-color: #18212c;
    }
}

.ais-ClearRefinements {

  .ais-ClearRefinements-button:hover {
    color: white;

    .ais-ClearRefinements-button::before {
      color: #fff !important;
    }
  }
}

.ais-Stats {
  margin: 10px;
  margin-bottom: 20px;
  margin-left: 0;
  filter: drop-shadow(1px 2px 4px #999);

  .ais-Stats-text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px;
    background: #222f3f;
    border-radius: 4px;
    color: white;
  }
}

#results-topbar {
  display: block;
  width: 100%;
  line-height: 22px;
  clear: both;
  margin-bottom: 16px;

  @include clearfix;

  .ais-Panel {
    float: right;

    &-header {
      font-weight: normal;
      font-size: .8em;
    }

    &-header,
    &-body {
      display: inline-block;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: 15px;
      border-bottom: none;
      padding-right: 10px;
    }
  }
}

.ais-Stats {
  float: left;

  &-body {
    padding-right: 10px;
    font-size: .8em;
    color: #777;
  }
}

.ais-Hits {
  @include clearfix;
  margin: 0 -.5%;

  .hit {
    width: 24%;
    float: left;
    padding: 10px 20px 20px;
    margin-bottom: 10px;
    border-bottom: solid 1px $gray-light;
    margin: .5%;
    border: solid 1px $gray-light;
    box-shadow: 0 0 3px lighten($gray-light, 3%);
    position: relative;

    @media(max-width: 960px) {
      width: 49%;
    }

    .product-picture-wrapper {
      display: table;
      table-layout: fixed;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }

    .product-picture {
      display: table-cell;
      vertical-align: middle;
      margin: 0 auto;

      img {
        transition: opacity 500ms cubic-bezier(.19, 1, .22, 1);
        height: 150px;
        max-width: 100%;
      }
    }

    .product-desc-wrapper {
      height: 100px;
      width: 100%;
      overflow: hidden;
    }

    .product-name {
      font-weight: bold;
      color: #000000;
      font-size: .9em;
      margin: 0 0 8px;
      min-width: 120px;
    }

    .product-type {
      font-size: .8em;
      margin: 0 0 10px;
      color: lighten($gray, 10%);
    }

    .product-rating {
      color: #fbae00;
      margin-bottom: 10px;
    }

    .product-price {
      font-size: 1.1em;
      font-weight: bold;
      color: #000000;
      float: right;
      letter-spacing: -1px;
    }

    em {
      color: #000000;
      font-style: normal;
      background-color: $primary-color;
    }
  }
}

.ais-Pagination-link {
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 600;
}

.ais-Panel-body .ais-RangeSlider {
  margin: 50px 0;
  padding: 0 35px 0 16px;

  .noUi-value-horizontal {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000;
    padding-top: 5px;
  }

  .noUi-horizontal .noUi-handle {
    margin-left: -7px;
    margin-top: -8px;
  }
  .noUi-pips-horizontal {
    padding: 5px 0;
  }
}

.ais-Pagination {
  @include clearfix;

  clear: both;
  margin-top: 0;
  background-color: aliceblue;
  font-size: 1.2em;
  line-height: 1.25;
  text-align: center;
  padding: 0;


  &-item--active a {
    color: #000000;
    font-weight: bold;
  }

  &-item--disabled {
    visibility: visible;

    a {
      color: darken($gray-light, 20%);
      &:hover {
        cursor: default;
        text-decoration: none;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    padding: 0 5px;
    display: inline-block;
  }
}

.thank-you {
  text-align: center;
  font-size: .8em;
  padding: 10px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ais-HierarchicalMenu {
  &-item--selected > a {
    font-weight: bold;
  }

  &-link::before {
    @extend .fa;
    content: "\f105";
  }

  &-count {
    display: none;
  }
}

.ais-ClearRefinements-button {
  color: #333;
  font-size: 14px;
  padding: 10px;
  font-weight: 600;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: #fff;
  border-color: #222F3F;
  display: block;
  width: 100%;

  &[disabled] {
    display: none;
  }

  &::before {
    @extend .fa;
    content: "\f12d";
  }
}

.ais-SearchBox {
  margin-right: 40px;
  &-input {

    height: 100%;
    padding: 8px 20px;
    font-size: 16px;
    color: #555;
    background-color: #fff;
    border-radius: 4px 0 0 4px;

    @media (min-width:1601px){
      height: 50px;
    }
  }

  &-reset {
    display: none;
  }

  &-submit {
    height: 100%;
    padding: 8px 20px;
    border: 0;
    background: #FFBE61;
    position: absolute;
    left: 100%;
    bottom: 0;
    border-radius: 0 4px 4px 0;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.ais-PriceRanges {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    cursor: pointer;

    &:hover {
      color: #B32500;
    }
  }
}

.ais-RatingMenu {
  svg {
    fill: #fbae00;
  }

  &-item--selected {
    font-weight: bold;
  }

  &-count {
    &:before {
      content: '(';
      margin-left: 5px;
    }

    &:after {
      content: ')';
    }
  }
}

.ais-VoiceSearch {
  display: flex;
  align-items: center;
  margin-left: 16px;

  .ais-VoiceSearch-button {
    color: #222f3f;
    width: 40px;
    height: 40px;

    @media (min-width: 1601px) {
      height: 45px;
      width: 45px;
    }

    svg {
      height: 40px;
      width: 40px;
      padding: 5px;
      background: white;
      border-radius: 50%;
      border: 2px solid #222f3f;

      @media (min-width: 1601px) {
        height: 45px;
        width: 45px;
      }
    }
  }

  .ais-VoiceSearch-status {
    color: black;
    margin-left: 10px;
    font-size: 14px;

      p {
        font-weight: 600;
        margin: 0;
        margin-right: 20px;
    }
  }
}

@media (max-width: 991px) {

  .ais-Pagination {

    .ais-Pagination-list {
      justify-content: flex-start;
      overflow-x: scroll;

      .ais-Pagination-item+.ais-Pagination-item {
        margin-left: 0;
      }

      .ais-Pagination-item--selected .ais-Pagination-link {
        margin-bottom: 10px;
      }

      .ais-Pagination-item--disabled .ais-Pagination-link {
        margin-bottom: 10px;
      }

      .ais-Pagination-link {
        margin-bottom: 10px;
      }
    }
  }
}
.toast-error {
   color: black;
   background-color: red;
   border-radius: 5px;
   padding: 20px;
   font-weight: 700;
   background-image: none!important;
   z-index: 999999999;

   button.toast-close-button {
      display: none!important;
   }
}

.p-galleria-settings .pi-chevron-right:before {
  content: "\203A"!important;
  font-size: 70px;
}

.p-galleria-settings.pi-chevron-left:before {
  content: "\2039"!important;
  font-size: 70px;
}

.pi-chevron-right:before {
  content: "\203A"!important;
  font-size: 50px;
}

.pi-chevron-left:before {
  content: "\2039"!important;
  font-size: 50px;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  margin-top: -12px;
}

.p-galleria-item-nav {
  transform: translate(-0, -50%);
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: auto;
  top: -7px;
  left: 0;
}

.ng-select-settings .ng-select-container {
  background-color: #f8f9fa;
  border: 1px solid #e5e5e5;
}
.table-ngselect  .ng-select-container {
  background-color: white;
  border: 1px solid white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  text-align: left;
}

  .error-message {
      display: flex;
      margin-top: 5px;
      margin-bottom: 16px;

      .fa-error {
        color: white;
        background: red;
        margin-right: 5px;
        font-size: 14px;
        border-radius: 50%;
        padding: 1px 5px;
      }

      span {
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 1px;
          color: red;
      }
  }
  .alert {
  
    margin: 0 !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;
    margin-top: 5px !important;
    color: red !important;
    text-align: left !important;
    display: flex;
    align-items: center;
    padding: 0;

      fa-icon {
          margin-right: 5px;
          font-size: 16px;
      }

}